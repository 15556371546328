import { addNavMenuItem } from "@vendure/admin-ui/core";

export default [
  addNavMenuItem(
    {
      id: "careers",
      label: "Job",
      routerLink: ["/extensions/jobs"],
      icon: "employee",
    },
    "marketing"
  ),
];
